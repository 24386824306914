import React from 'react';
import { graphql } from 'gatsby';
import { StructuredTextDocument } from 'react-datocms/dist/esm';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Container from '../../../primitives/grid/container';
import AspectRatio from '../../../primitives/aspect-ratio';
import Background from '../../../primitives/backgrounds';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import Check from './check.svg';
import Link, { getLink } from '../../../primitives/link';
import VisuallyHidden from '../../../primitives/visually-hidden';

export type BenefitSetProps = Queries.BenefitSetPropsFragment;
const BenefitSet = ({ richTitle, cards }: BenefitSetProps): JSX.Element => (
    <Container css={{
        marginTop: '94px',
        marginBottom: '100px',
        [breakpoints.mb]: {
            marginTop: '50px',
            marginBottom: '55px',
        },
    }}
    >
        <Typography
            fontSize={{
                dt: 4053,
                tb: 3234,
                mb: 2429,
            }}
            override
            css={{
                textAlign: 'center',
                marginBottom: '60px',
                [breakpoints.mb]: {
                    marginBottom: '40px',
                },
            }}
            fontWeight="light"
        >
            <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
        </Typography>
        <Row css={{
            [breakpoints.mb]: {
                rowGap: '12px',
            },
        }}
        >
            {cards && cards.map((card) => {
                if (!card) return null;

                const { benefit, link } = card;

                return (
                    <Col breakpoints={{ dt: { span: 4 } }} css={{ position: 'relative' }}>
                        <AspectRatio
                            breakpoints={{
                                dt: { width: 392, height: 265 },
                                mb: 'none',
                            }}
                        >
                            <Background
                                breakpoints={{
                                    dt: {
                                        gradient: 'tangerine',
                                    },
                                }}
                                css={{
                                    height: '100%',
                                    borderRadius: borderRadius.large,
                                    paddingTop: '36px',
                                    paddingLeft: '40px',
                                    paddingBottom: '42px',
                                    paddingRight: '40px',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                    [breakpoints.tb]: {
                                        padding: '24px',
                                    },
                                    [breakpoints.mb]: {
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        padding: '14px 18px',
                                        gap: '14px',
                                        borderRadius: borderRadius.medium,
                                    },
                                }}
                            >
                                <div>
                                    <Check css={{
                                        width: '38px',
                                        height: '38px',
                                        [breakpoints.tb]: {
                                            width: '28px',
                                            height: '28px',
                                        },
                                    }}
                                    />
                                </div>
                                <Typography
                                    aria-hidden="true"
                                    fontSize={{
                                        dt: 3234,
                                        tb: 2429,
                                        mb: 1822,
                                    }}
                                    color="shadesWhite"
                                    override
                                    fontWeight="light"
                                    css={{
                                        textAlign: 'left',
                                        [breakpoints.tb]: {
                                            br: {
                                                content: '""',
                                                '&:after': {
                                                    content: '" "',
                                                },
                                            },
                                            p: {
                                                display: 'inline-block',
                                                marginRight: '3px',
                                            },
                                        },
                                    }}
                                >
                                    <CustomStructuredTextRenderer
                                        data={benefit as unknown as StructuredTextDocument}
                                    />
                                </Typography>
                            </Background>
                        </AspectRatio>
                        {(link && link.length > 0) && (
                            <Link
                                css={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                                to={getLink(link[0].link)}
                            >
                                <VisuallyHidden>
                                    <CustomStructuredTextRenderer
                                        data={benefit as unknown as StructuredTextDocument}
                                    />
                                </VisuallyHidden>
                            </Link>
                        )}
                    </Col>
                );
            })}
        </Row>
    </Container>
);

export default BenefitSet;

export const query = graphql`
    fragment BenefitSetProps on DatoCmsBenefitSet {
        richTitle {
            blocks
            links
            value
        }
        cards {
            benefit {
                blocks
                links
                value
            }
            link {
                 ...LinkWithoutTitleProps
            }
        }
    }
`;
